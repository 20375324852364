import React from 'react';
import HeroSection from './sections/HeroSection';
import InfoSection from './sections/InfoSection';
// import PhraseSection from './sections/PhraseSection';
import ContentSection from './sections/ContentSection';
import DoctorsSaySection from './sections/DoctorsSaySection';
// import ViveteSection from './sections/ViveteSection';
// import AuthorsSection from './sections/AuthorsSection';
import NuriaSection from './sections/NuriaSection';
// import PopUpCampaign from '../../../components/PopUpCampaign';
import { Helmet } from 'react-helmet';
// import { usePopup } from '../../../hooks/usePopup';

const AgendaScreen = () => {
  // const { isPopupOpen, triggerRef, closePopup} = usePopup();

  return (
    <main className="page">
      <Helmet>
        <title>Agenda 2024 | Mujer Sin Reglas</title>
      </Helmet>
      <HeroSection />
      <InfoSection />
      <ContentSection />
      <DoctorsSaySection />
      {/* <ViveteSection /> */}
      <NuriaSection />
      {/* <div ref={triggerRef} />
      {isPopupOpen && (
        <PopUpCampaign isOpen={isPopupOpen} closeModal={closePopup} />
      )} */}
    </main>
  );
};

export default AgendaScreen;
